export const LinkedInLogo = () => (
  <svg
    height="32"
    viewBox="0 0 24 24"
    width="32"
    version="1.1"
    id="svg2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />
    <g fill="none" fillRule="evenodd" id="g2" transform="scale(0.33333333)">
      <path
        d="m 8,72 h 56 c 4.418278,0 8,-3.581722 8,-8 V 8 C 72,3.581722 68.418278,0 64,0 H 8 C 3.581722,0 0,3.581722 0,8 v 56 c 0,4.418278 3.581722,8 8,8 z"
        fill="#007ebb"
        id="path1"
      />
      <path
        d="M 62,62 H 51.315625 V 43.802115 c 0,-4.989361 -1.895833,-7.777583 -5.844922,-7.777583 -4.296094,0 -6.540625,2.901578 -6.540625,7.777583 V 62 H 28.633333 V 27.333333 h 10.296745 v 4.669595 c 0,0 3.095964,-5.728713 10.452474,-5.728713 C 56.735677,26.274215 62,30.764471 62,40.051212 Z M 16.349349,22.794013 C 12.842057,22.794013 10,19.929657 10,16.397007 10,12.864357 12.842057,10 16.349349,10 c 3.507292,0 6.347656,2.864357 6.347656,6.397007 0,3.53265 -2.840364,6.397006 -6.347656,6.397006 z M 11.032552,62 H 21.769401 V 27.333333 H 11.032552 Z"
        fill="#ffffff"
        id="path2"
      />
    </g>
  </svg>
);
