import { Box, Heading } from "@chakra-ui/react";
import LoginProviders from "./login-providers";
import YottaVisionLogoVertical from "./yotta-vision-logo-vertical";

export default function LoginModalComponent() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bg="subtle-bg"
      padding="30px 10px 30px 10px"
    >
      <Box
        as="section"
        width="45%"
        display="flex"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <YottaVisionLogoVertical role="img" />
      </Box>
      <Box
        as="section"
        display="flex"
        bg="loginModal"
        borderRadius="8px"
        flexDirection="column"
        width="50%"
        p={8}
      >
        <Heading color="text">Welcome</Heading>
        <Heading color="text" fontWeight="normal" size="xs">
          Log in with the email you submitted for access, that is associated
          with one of the below third party authenticators.
        </Heading>
        <LoginProviders />
      </Box>
    </Box>
  );
}
