import { Button, Flex } from "@chakra-ui/react";
import NextLink from "next/link";
import { GoogleLogo } from "./google-logo";
import { LinkedInLogo } from "./linkedin-logo";
import { MicrosoftLogo } from "./microsoft-logo";

export default function LoginProviders() {
  return (
    <Flex
      width="80%"
      alignSelf="center"
      justifyContent="space-between"
      gap={8}
      mt={8}
    >
      <Button
        variant="providerButton"
        as={NextLink}
        href={`${process.env.NEXT_PUBLIC_API_URL}/auth/google/authorize`}
        passHref
      >
        <GoogleLogo />
      </Button>
      <Button
        variant="providerButton"
        as={NextLink}
        href={`${process.env.NEXT_PUBLIC_API_URL}/auth/microsoft/authorize`}
        passHref
      >
        <MicrosoftLogo />
      </Button>
      <Button
        variant="providerButton"
        as={NextLink}
        href={`${process.env.NEXT_PUBLIC_API_URL}/auth/linkedin/authorize`}
        passHref
      >
        <LinkedInLogo />
      </Button>
    </Flex>
  );
}
