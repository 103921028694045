export const MicrosoftLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <rect x="1" y="1" width="11" height="11" fill="#f25022" />
    <rect x="1" y="13" width="11" height="11" fill="#00a4ef" />
    <rect x="13" y="1" width="11" height="11" fill="#7fba00" />
    <rect x="13" y="13" width="11" height="11" fill="#ffb900" />
  </svg>
);
